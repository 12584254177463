import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import RocketIcon from '@mui/icons-material/Rocket';
import imageIndex from '../ImageIndex';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useState, useEffect } from 'react';

import { Chip as MuiChip } from '@mui/material';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import 'react-image-lightbox/style.css';

const ImageCarousel2 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const isMobile2 = () => window.innerWidth <= 768;

  const adjustH1StylesForMobile2 = () => {
    if (isMobile2()) {
      document.querySelectorAll('.css-1j9ggd5-MuiTypography-root').forEach(h1 => {
        h1.style.fontSize = '20px';
      });
    }
  };

  adjustH1StylesForMobile2();

  return (
    <Container sx={{ width: { xs: '100%', sm: '100%' }, margin: '0 auto' }}>
      <Slider {...settings}>
        <div style={{ ...carouselImageStyle2, borderRadius: '12px', overflow: 'hidden' }}>
          <img src={imageIndex.ENMICE202519} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '12px' }}/>
        </div>
        <div style={{ ...carouselImageStyle2, borderRadius: '12px', overflow: 'hidden' }}>
          <img src={imageIndex.ENMICE202520} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '12px' }}/>
        </div>
        <div style={{ ...carouselImageStyle2, borderRadius: '12px', overflow: 'hidden' }}>
          <img src={imageIndex.ENMICE202518} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '12px' }}/>
        </div>
      </Slider>
    </Container>
    );
    };

    const ImageCarousel3 = () => {
      const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
      };
    
      const isMobile3 = () => window.innerWidth <= 768;
    
      const adjustH1StylesForMobile3 = () => {
        if (isMobile3()) {
          document.querySelectorAll('.css-1j9ggd5-MuiTypography-root').forEach(h1 => {
            h1.style.fontSize = '20px';
          });
        }
      };
    
      adjustH1StylesForMobile3();
    
      return (
        <Container sx={{ width: { xs: '100%', sm: '100%' }, margin: '0 auto' }}>
          <Slider {...settings}>
            <div style={{ ...carouselImageStyle2, borderRadius: '12px', overflow: 'hidden' }}>
              <img src={imageIndex.ENMICE202522} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '12px' }}/>
            </div>
            <div style={{ ...carouselImageStyle2, borderRadius: '12px', overflow: 'hidden' }}>
              <img src={imageIndex.ENMICE202523} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '12px' }}/>
            </div>
            <div style={{ ...carouselImageStyle2, borderRadius: '12px', overflow: 'hidden' }}>
              <img src={imageIndex.ENMICE202524} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '12px' }}/>
            </div>
            <div style={{ ...carouselImageStyle2, borderRadius: '12px', overflow: 'hidden' }}>
              <img src={imageIndex.ENMICE202514} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '12px' }}/>
            </div>
            <div style={{ ...carouselImageStyle2, borderRadius: '12px', overflow: 'hidden' }}>
              <img src={imageIndex.ENMICE202515} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '12px' }}/>
            </div>
            <div style={{ ...carouselImageStyle2, borderRadius: '12px', overflow: 'hidden' }}>
              <img src={imageIndex.ENMICE202516} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '12px' }}/>
            </div>
          </Slider>
        </Container>
        );
        };

  const carouselImageStyle2 = {
    width: '100%', // Adjust width as needed
    height: '500px', // Adjust height as needed
    objectFit: 'cover', // This ensures the images cover the div without stretching
    position: 'relative',
    borderRadius: '25px',
  };


const items = [
  {
    description: '¡El <strong style="color: hsl(210, 100%, 65%);">Encuentro Mexicano de Ingeniería en Cohetería Experimental (ENMICE) 2025</strong> regresa con un formato renovado! Este año, la ciudad de <strong style="color: hsl(210, 100%, 65%);">Zapopan y la Laguna de Sayula, en el estado de Jalisco</strong>, serán las sedes del <strong style="color: hsl(210, 100%, 65%);">18 al 21 de noviembre de 2025</strong> del evento aeroespacial más grande e importante sobre cohetes y cargas útiles en todo México.\n\n<strong style="color: hsl(210, 100%, 65%);">Novedades de la competencia 2025:</strong>.\n\n<strong style="color: hsl(210, 100%, 65%);">• Enfoque en lanzamientos:</strong> 2 días dedicados 100 % a lanzamientos de cohetes de competencia y de demostración.\n<strong style="color: hsl(210, 100%, 65%);">• Banco de pruebas de motores:</strong> Haz la ignición de tu motor en un ambiente seguro y demuestra tu talento y capacidad para desarrollar motores experimentales.\n<strong style="color: hsl(210, 100%, 65%);">• Optimización en la evaluación:</strong> Consulta al momento tu calificación como equipo participante; transparencia y accesibilidad.\n<strong style="color: hsl(210, 100%, 65%);">• Disminuimos el número de entregables:</strong> Sin descuidar la calidad y seguridad en los trabajos, buscamos que los equipos se concentren en el desarrollo de sus proyectos.\n<strong style="color: hsl(210, 100%, 65%);">• Exposición de proyectos:</strong> Un espacio para mostrar los proyectos aeroespaciales más innovadores que forman parte del nuevo capítulo espacial mexicano.',
    url: 'https://google.com',
  }
];

const items2 = [
  {
    title: '<strong style="color: hsl(210, 100%, 65%);">53</strong> equipos registrados\n<strong style="color: hsl(210, 100%, 65%);">20</strong> estados participantes\n<strong style="color: hsl(210, 100%, 65%);"># por definir</strong> participantes\n\n',
    description: 'En el año 2022 llevamos a cabo nuestra segunda edición en tierras jaliscienses con un éxito total. Este 2025, traemos a casa la cuarta edición de ENMICE donde exploramos nuevamente no solo la ingeniería y el espacio, sino también la riqueza cultural y tecnológica de Jalisco. Desde los paisajes únicos de la Laguna de Sayula, ideal para pruebas de cohetes, hasta el vibrante entorno de <strong style="color: hsl(210, 100%, 65%);">Zapopan</strong>, una de las ciudades más modernas e innovadoras de México y Latinoamérica.\n\n<strong style="color: hsl(210, 100%, 65%);">ENMICE es una marca de Explora Space, startup espacial mexicana establecida en Jalisco y con presencia nacional.</strong>',
    imageDark: `url(${imageIndex.ENMICE20232024Map})`,
    url: 'https://google.com',
  }
];

const items3 = [
  {
    title: '<strong style="color: hsl(210, 100%, 65%);">BRIEF ENMICE 2025:</strong> -.\n<strong style="color: hsl(210, 100%, 65%);">CONVOCATORIA 2025:</strong> -.\n<strong style="color: hsl(210, 100%, 65%);">PRE-REGISTRO 2025:</strong> -.\n\n',
    description: '¡El <strong style="color: hsl(210, 100%, 65%);">Encuentro Mexicano de Ingeniería en Cohetería Experimental (ENMICE) 2025</strong> regresa con un formato renovado! Este año, la ciudad de <strong style="color: hsl(210, 100%, 65%);">Zapopan y la Laguna de Sayula, en el estado de Jalisco</strong>, serán las sedes del <strong style="color: hsl(210, 100%, 65%);">18 al 21 de noviembre de 2025</strong> del evento aeroespacial más grande e importante sobre cohetes con cargas útiles en todo México.\n\n',
    description2: '<strong style="color: hsl(210, 100%, 65%);">• Enfoque en lanzamientos:</strong> 2 días dedicados 100 % a lanzamientos de cohetes de competencia y de demostración.\n<strong style="color: hsl(210, 100%, 65%);">• Banco de pruebas de motores:</strong> Haz la ignición de tu motor en un ambiente seguro y demuestra tu talento y capacidad para desarrollar motores experimentales.\n<strong style="color: hsl(210, 100%, 65%);">• Optimización en la evaluación:</strong> Consulta al momento tu calificación como equipo participante; transparencia y accesibilidad.\n<strong style="color: hsl(210, 100%, 65%);">• Disminuimos el número de entregables:</strong> Sin descuidar la calidad y seguridad en los trabajos, buscamos que los equipos se concentren en el desarrollo de sus proyectos.\n<strong style="color: hsl(210, 100%, 65%);">• Exposición de proyectos:</strong> Un espacio para mostrar los proyectos aeroespaciales más innovadores que forman parte del nuevo capítulo espacial mexicano.',
    imageDark: `url(${imageIndex.ENMICE202202})`,
    url: 'https://google.com',
  }
];

const items5 = [
  {
    description: 'Participar en la competencia de <strong style="color: hsl(210, 100%, 65%);">ENMICE 2025</strong> representa una oportunidad única para que equipos de estudiantes y profesionales impulsen sus habilidades en el desarrollo de <strong style="color: hsl(210, 100%, 65%);">proyectos de cohetes y cargas útiles</strong>, áreas clave para el futuro del sector <strong style="color: hsl(210, 100%, 65%);">aeroespacial y espacial mexicano</strong>.\n\nAl formar parte de este evento, los equipos, participantes, colaboradores, patrocinadores y asistentes contribuyen a fortalecer la <strong style="color: hsl(210, 100%, 65%);">capacidad tecnológica y el talento nacional</strong>, así como en el impulso de una <strong style="color: hsl(210, 100%, 65%);">regulación espacial en México que permita realizar lanzamientos suborbitales y orbitales</strong>, promoviendo el interés en estas áreas y facilitando el desarrollo de políticas públicas y normativas que permitan llevar a cabo este tipo de actividades de manera más eficiente y con visión a largo plazo.\n\nAsiste a ENMICE 2025 e intégrate al grupo de actores que <strong style="color: hsl(210, 100%, 65%);">construyen un ecosistema espacial sólido</strong>, preparando el terreno para que México realice <strong style="color: hsl(210, 100%, 65%);">lanzamientos espaciales</strong> en el futuro y continúe su camino como un referente en la industria espacial global. ',
    imageDark: `url(${imageIndex.ENMICE202531})`,
    url: 'https://google.com',
  }
];

const items6 = [
  {
    description: 'ENMICE impulsa el desarrollo tecnológico y la formación de talento mexicano en <strong style="color: hsl(210, 100%, 65%);">cohetería experimental, de alta potencia y cargas útiles</strong>, disciplinas claves para la industria <strong style="color: hsl(210, 100%, 65%);">aeroespacial y espacial</strong> del país. Al reunir a estudiantes, profesionales y expertos, el evento fomenta la <strong style="color: hsl(210, 100%, 65%);">innovación</strong>, la <strong style="color: hsl(210, 100%, 65%);">colaboración</strong> y el intercambio de conocimientos, posicionando a México como un referente en el desarrollo y crecimiento de tecnología espacial. Además, promueve la creación de soluciones prácticas y aplicables en áreas como: <strong style="color: hsl(210, 100%, 65%);">sistemas de propulsión, materiales avanzados y cargas útiles</strong>, fortaleciendo así el crecimiento del sector aeroespacial nacional.\n\nEl <strong style="color: hsl(210, 100%, 65%);">ENMICE</strong> también desempeña un papel fundamental en la <strong style="color: hsl(210, 100%, 65%);">regulación y desarrollo de actividades espaciales en México</strong>, al generar espacios de diálogo, divulgación y exhibición de proyectos científico-tecnológicos. Nuestra participación activa en la promoción de la <strong style="color: hsl(210, 100%, 65%);">Reforma Espacial Mexicana</strong> busca reconocer las actividades espaciales como una prioridad nacional, impulsando políticas públicas que faciliten la innovación, el financiamiento y el crecimiento sostenible del ecosistema espacial mexicano, especialmente en el área de vehículos lanzadores y cargas útiles como satélites, drones, rovers, entre otros.',
    imageDark: `url(${imageIndex.ENMICE202521})`,
    url: 'https://google.com',
  }
];

const items4 = [
  {
    icon: <RocketIcon />,
    title: 'Competencia',
    imageDark: `url(${imageIndex.ENMICE202526})`,
    url: 'https://forms.gle/AS52JzKLMRyvid9K6',
  },
  {
    icon: <RocketIcon />,
    title: 'Lanzamiento de exhibición',
    imageDark: `url(${imageIndex.ENMICE202527})`,
    url: 'https://forms.gle/AS52JzKLMRyvid9K6',
  },
  {
    icon: <RocketIcon />,
    title: 'Patrocinador',
    imageDark: `url(${imageIndex.ENMICE202528})`,
    url: 'https://drive.google.com/file/d/1UmvRwtouVwM88RYxDgwBaykJH-vyg67n/view?usp=sharing',
  },
  {
    icon: <RocketIcon />,
    title: 'Público General',
    imageDark: `url(${imageIndex.ENMICE202529})`,
    url: 'https://enmice.mx',
  },

  {
    icon: <RocketIcon />,
    title: 'Voluntario/a',
    imageDark: `url(${imageIndex.ENMICE202530})`,
    url: 'https://enmice.mx',
  },
];

const Chip = styled(MuiChip)(({ theme }) => ({
  variants: [
    {
      props: ({ selected }) => selected,
      style: {
        background: 'linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))',
        color: 'hsl(0, 0%, 100%)',
        borderColor: theme.palette.primary.light,
        '& .MuiChip-label': {
          color: 'hsl(0, 0%, 100%)',
        },
        ...theme.applyStyles('dark', {
          borderColor: theme.palette.primary.dark,
        }),
      },
    },
  ],
}));

const ImageCarousel = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', background: 'none', right: '10px', zIndex: 1 }}
        onClick={onClick}
      />
    );
  };
  
  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', background: 'none', left: '10px', zIndex: 1 }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <SampleNextArrow />, // Custom next arrow
    prevArrow: <SamplePrevArrow />, // Custom previous arrow
  };

  const carouselImageStyle = {
    width: '100%', // Adjust width as needed
    height: '500px', // Adjust height as needed
    objectFit: 'cover', // This ensures the images cover the div without stretching
    position: 'relative',
  };

  return (
    <Slider {...settings}>
      <div style={carouselImageStyle}>
        <img src={imageIndex.ENMICE202513} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
      </div>
      <div style={carouselImageStyle}>
        <img src={imageIndex.ENMICE202502} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
      </div>
      <div style={carouselImageStyle}>
        <img src={imageIndex.ENMICE202504} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
      </div>
      <div style={carouselImageStyle}>
        <img src={imageIndex.ENMICE202501} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
      </div>
      <div style={carouselImageStyle}>
        <img src={imageIndex.ENMICE202503} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
      </div>
    </Slider>
  );
};

const ImageCarousel4 = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const carouselImageStyle = {
    width: '100%', // Adjust width as needed
    height: '500px', // Adjust height as needed
    objectFit: 'cover', // This ensures the images cover the div without stretching
    position: 'relative',
  };

  return (
    <Slider {...settings}>
      <div style={carouselImageStyle}>
        <img src={imageIndex.ENMICE202505} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
      </div>
      <div style={carouselImageStyle}>
        <img src={imageIndex.ENMICE202506} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
      </div>
      <div style={carouselImageStyle}>
        <img src={imageIndex.ENMICE202507} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
      </div>
    </Slider>
  );
};

export default function ENMICE2025Hero() {
  const [selectedItemIndex] = React.useState(0);
  const selectedFeature = items[selectedItemIndex];
  const selectedFeature2 = items2[selectedItemIndex];

  const [selectedItemIndex4, setSelectedItemIndex] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setSelectedItemIndex((prevIndex) => (prevIndex + 1) % items4.length);
    }, 5000); // Change card every 3 seconds

    return () => clearInterval(interval);
  }, []);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature4 = items4[selectedItemIndex4];

  return (
    <React.Fragment>
      <Box
        id="hero"
        sx={(theme) => ({
          width: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
          ...theme.applyStyles('dark', {
            backgroundImage:
              'radial-gradient(ellipse 70% 110% at 50% -20%, hsl(210, 100%, 16%), transparent)',
          }),
        })}
      >
        <ImageCarousel4 />
      </Box>

    <Container id="features" sx={{ py: { xs: 4, sm: 6 } }}>
          <div>
            <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
              <Box sx={{ display: 'inline-block' }}>
                <Typography variant="h2" sx={{ color: 'text.primary', marginRight: 1 }}>
                  ¡Sé parte del futuro aeroespacial
                </Typography>
                <Typography variant="h2" sx={{ color: 'primary.light' }}>
                  participando en ENMICE!
                </Typography>
              </Box>
            </Box>
          </div>
      <Grid container spacing={6}>
        <Grid item xs={12} md={5} sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}>
              <Grid
          sx={{
            display: { xs: 'none', sm: 'flex' },
            width: '100%',
            alignItems: 'center', // Center vertically
            justifyContent: 'center', // Center horizontally if needed
            flexDirection: 'column', // Stack children vertically
          }}
        >
          <ImageCarousel2 />
          <Typography gutterBottom variant="h6" sx={{ color: 'text.primary', fontWeight: 'medium', mt: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                href="https://drive.google.com/file/d/1kfdcHD8ohAUw30sM9M31rGRR9jBPLXxN/view?usp=sharing"
                target="_blank"
                sx={{ padding: '16px 24px', fontSize: '18px' }}
              >
                BRIEF ENMICE 2025
              </Button>
              <Button
                variant="contained"
                color="primary"
                href="https://drive.google.com/drive/folders/1Dk136kqCetSLGZdVenTXwDJh70cjE1tQ?usp=drive_link"
                target="_blank"
                sx={{ padding: '16px 24px', fontSize: '18px' }}
              >
                CONVOCATORIA 2025
              </Button>
              <Button
                variant="contained"
                color="primary"
                href="https://forms.gle/AS52JzKLMRyvid9K6"
                target="_blank"
                sx={{ padding: '16px 24px', fontSize: '18px' }}
              >
                PRE-REGISTRO 2025
              </Button>
            </Box>
          </Typography>
        </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <Card variant="outlined" sx={{ display: { xs: 'auto', sm: 'none' }, mt: 4 }}>
            <Box
              sx={(theme) => ({
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: 280,
                backgroundImage: 'var(--items-imageLight)',
                ...theme.applyStyles('dark', {
                  backgroundImage: 'var(--items-imageDark)',
                }),
              })}
              style={{
                '--items-imageLight': items3[selectedItemIndex].imageLight,
                '--items-imageDark': items3[selectedItemIndex].imageDark,
              }}
            />
            <Box sx={{ px: 2, pb: 2, pt: 2 }}>
              <Typography variant="h6" sx={{ color: 'text.primary', mb: 1.5 }}
                    dangerouslySetInnerHTML={{ __html: selectedFeature.description.replace(/\n/g, '<br>') }}>
              </Typography>
            </Box>
          </Card>
          <Stack
            direction="column"
            spacing={2}
            useFlexGap
            sx={{
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
            }}
          >
            {items3.map(({ icon, title, description, description2 }, index) => (
              <Card
                key={index}
                component={Button}
                sx={[
                  (theme) => ({
                    p: 3,
                    height: 'fit-content',
                    width: '100%',
                    background: 'none',
                    '&:hover': {
                      background: 'linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)',
                      borderColor: 'primary.light',
                      boxShadow: '0px 2px 8px hsla(0, 0%, 0%, 0.1)',
                      ...theme.applyStyles('dark', {
                        background: 'linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)',
                        borderColor: 'primary.dark',
                        boxShadow: '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                      }),
                    },
                  }),
                  selectedItemIndex === index &&
                    ((theme) => ({
                      backgroundColor: 'action.selected',
                      borderColor: 'primary.light',
                      ...theme.applyStyles('dark', {
                        borderColor: 'primary.dark',
                      }),
                    })),
                ]}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={[
                      (theme) => ({
                        color: 'grey.400',
                        ...theme.applyStyles('dark', {
                          color: 'grey.600',
                        }),
                      }),
                      selectedItemIndex === index && {
                        color: 'primary.main',
                      },
                    ]}
                  >
                    {icon}
                  </Box>
                  <div>
                    <Typography variant="h6" sx={{ color: 'text.primary', mb: 1.5, whiteSpace: 'pre-line'  }}
                    dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, '<br>') }}>
                    </Typography>
                    <Typography variant="h4" sx={{ color: 'text.primary', mb: 1.5, whiteSpace: 'pre-line', fontWeight: 'bold'  }}>
                      Novedades de la competencia 2025:
                    </Typography>
                    <Typography variant="h6" sx={{ color: 'text.primary', mb: 1.5, whiteSpace: 'pre-line'  }}
                    dangerouslySetInnerHTML={{ __html: description2.replace(/\n/g, '<br>') }}>
                    </Typography>
                  </div>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Container>

    <Container id="features" sx={{ py: { xs: 4, sm: 8 }, mb: 6  }}>
      <div>
        <Box sx={{ marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant="h2" sx={{ color: 'text.primary', marginRight: 1 }}>
            ENMICE
          </Typography>
          <Typography variant="h2" sx={{ color: 'primary.light' }}>
            de vuelta en casa
          </Typography>
        </Box>
      </div>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Card variant="outlined" sx={{ display: { xs: 'auto', sm: 'none' }, mt: 4 }}>
            <ImageCarousel3 />
            <Box sx={{ px: 2, pb: 2 }}>
              <Typography variant="h6" sx={{ color: 'text.primary', mb: 1.5, whiteSpace: 'pre-line'  }}
                    dangerouslySetInnerHTML={{ __html: selectedFeature2.description.replace(/\n/g, '<br>') }}>
              </Typography>
            </Box>
          </Card>
          <Stack
            direction="column"
            spacing={2}
            useFlexGap
            sx={{
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
            }}
          >
            {items2.map(({ icon, description }, index) => (
              <Card
                key={index}
                component={Button}
                sx={[
                  (theme) => ({
                    p: 3,
                    height: 'fit-content',
                    width: '100%',
                    background: 'none',
                    '&:hover': {
                      background: 'linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)',
                      borderColor: 'primary.light',
                      boxShadow: '0px 2px 8px hsla(0, 0%, 0%, 0.1)',
                      ...theme.applyStyles('dark', {
                        background: 'linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)',
                        borderColor: 'primary.dark',
                        boxShadow: '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                      }),
                    },
                  }),
                  selectedItemIndex === index &&
                    ((theme) => ({
                      backgroundColor: 'action.selected',
                      borderColor: 'primary.light',
                      ...theme.applyStyles('dark', {
                        borderColor: 'primary.dark',
                      }),
                    })),
                ]}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={[
                      (theme) => ({
                        color: 'grey.400',
                        ...theme.applyStyles('dark', {
                          color: 'grey.600',
                        }),
                      }),
                      selectedItemIndex === index && {
                        color: 'primary.main',
                      },
                    ]}
                  >
                    {icon}
                  </Box>
                  <div>
                    <Typography variant="h6" sx={{ color: 'text.primary', mb: 1.5, whiteSpace: 'pre-line'  }}
                    dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, '<br>') }}>
                    </Typography>
                  </div>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}
          sx={{
            display: { xs: 'none', sm: 'flex' },
            width: '100%',
            alignItems: 'center', // Center vertically
            justifyContent: 'center', // Center horizontally if needed
          }}
        >
          <ImageCarousel3 />
        </Grid>
      </Grid>
    </Container>

    <ImageCarousel />

    <Container id="features" sx={{ py: { xs: 4, sm: 6 }, mt: 6 }}>
      <div>
        <Box sx={{ marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant="h2" sx={{ color: 'text.primary', marginRight: 1 }}>
            ¿Por qué es importante
          </Typography>
          <Typography variant="h2" sx={{ color: 'primary.light' }}>
            ENMICE?
          </Typography>
        </Box>
      </div>
      <Grid container spacing={6}>
      <Grid item xs={12} md={6} sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}>
      <Grid
          sx={{
            display: { xs: 'none', sm: 'flex' },
            width: '100%',
            alignItems: 'center', // Center vertically
            justifyContent: 'center', // Center horizontally if needed
          }}
        >
            <Box
              sx={(theme) => ({
                width: '100%',
                minHeight: '700px',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                overflow: 'hidden',
                backgroundImage: 'var(--items-imageLight)',
                ...theme.applyStyles('dark', {
                  backgroundImage: 'var(--items-imageDark)',
                }),
              })}
              style={{
                '--items-imageLight': items6[selectedItemIndex].imageLight,
                '--items-imageDark': items6[selectedItemIndex].imageDark,
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card variant="outlined" sx={{ display: { xs: 'auto', sm: 'none' }, mt: 4 }}>
            <Box
              sx={(theme) => ({
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: 280,
                backgroundImage: 'var(--items6-imageLight)',
                ...theme.applyStyles('dark', {
                  backgroundImage: 'var(--items6-imageDark)',
                }),
              })}
              style={{
                '--items6-imageLight': items6[selectedItemIndex].imageLight,
                '--items6-imageDark': items6[selectedItemIndex].imageDark,
              }}
            />
            <Box sx={{ px: 2, pb: 2, pt: 2 }}>
              <Typography variant="h6" sx={{ color: 'text.primary', mb: 1.5 }}
                    dangerouslySetInnerHTML={{ __html: selectedFeature.description.replace(/\n/g, '<br>') }}>
              </Typography>
            </Box>
          </Card>
          <Stack
            direction="column"
            spacing={2}
            useFlexGap
            sx={{
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
            }}
          >
            {items6.map(({ icon, description }, index) => (
              <Card
                key={index}
                component={Button}
                sx={[
                  (theme) => ({
                    p: 3,
                    height: 'fit-content',
                    width: '100%',
                    background: 'none',
                    '&:hover': {
                      background: 'linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)',
                      borderColor: 'primary.light',
                      boxShadow: '0px 2px 8px hsla(0, 0%, 0%, 0.1)',
                      ...theme.applyStyles('dark', {
                        background: 'linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)',
                        borderColor: 'primary.dark',
                        boxShadow: '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                      }),
                    },
                  }),
                  selectedItemIndex === index &&
                    ((theme) => ({
                      backgroundColor: 'action.selected',
                      borderColor: 'primary.light',
                      ...theme.applyStyles('dark', {
                        borderColor: 'primary.dark',
                      }),
                    })),
                ]}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={[
                      (theme) => ({
                        color: 'grey.400',
                        ...theme.applyStyles('dark', {
                          color: 'grey.600',
                        }),
                      }),
                      selectedItemIndex === index && {
                        color: 'primary.main',
                      },
                    ]}
                  >
                    {icon}
                  </Box>
                  <div>
                    <Typography variant="h6" sx={{ color: 'text.primary', mb: 1.5, whiteSpace: 'pre-line'  }}
                    dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, '<br>') }}>
                    </Typography>
                  </div>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Container>

    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <div>
        <Box sx={{ marginBottom: '20px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="h2" sx={{ color: 'text.primary', marginRight: 1 }}>
              Tú eliges
            </Typography>
            <Typography variant="h2" sx={{ color: 'primary.light' }}>
              cómo participar
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body1" sx={{ color: 'text.secondary', mb: { xs: 2, sm: 4 }, mt: 2 }}>
              Ya sea como equipo participante en la competencia, asistente general, colaborador o patrocinador, estamos felices de que seas parte de este esfuerzo nacional.
            </Typography>
          </Box>
        </Box>
      </div>
      <Grid container spacing={6}>
        <Grid item xs={12} md={7}>
          <Grid container item sx={{ gap: 1, display: { xs: 'auto', sm: 'none' } }}>
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                selected={selectedItemIndex4 === index}
              />
            ))}
          </Grid>
          <Card variant="outlined" sx={{ display: { xs: 'auto', sm: 'none' }, mt: 4 }}>
            <Box
              sx={(theme) => ({
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: 280,
                backgroundImage: 'var(--items-imageLight)',
                ...theme.applyStyles('dark', {
                  backgroundImage: 'var(--items-imageDark)',
                }),
              })}
              style={{
                '--items-imageLight': items4[selectedItemIndex4].imageLight,
                '--items-imageDark': items4[selectedItemIndex4].imageDark,
              }}
            />
            <Box sx={{ px: 2, pb: 2 }}>
              <Typography gutterBottom sx={{ color: 'text.primary', fontWeight: 'medium' }}>
                {selectedFeature4.title}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1.5 }}>
                {selectedFeature4.description}
              </Typography>
              <Link
                href={selectedFeature4.url}
                target="_blank"
                color="primary"
                variant="body2"
                sx={{
                  fontWeight: 'bold',
                  display: 'inline-flex',
                  alignItems: 'center',
                  '& > svg': { transition: '0.2s' },
                  '&:hover > svg': { transform: 'translateX(2px)' },
                }}
              >
                <span>Registrarme en modalidad</span>
                <ChevronRightRoundedIcon fontSize="small" sx={{ mt: '1px', ml: '2px' }} />
              </Link>
            </Box>
          </Card>
          <Stack
            direction="column"
            spacing={2}
            useFlexGap
            sx={{
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
            }}
          >
            {items4.map(({ icon, title, description, url }, index) => (
              <Card
                key={index}
                component={Button}
                onClick={() => window.open(url, '_blank')}
                sx={[
                  (theme) => ({
                    p: 3,
                    height: 'fit-content',
                    width: '100%',
                    background: 'none',
                    '&:hover': {
                      background: 'linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)',
                      borderColor: 'primary.light',
                      boxShadow: '0px 2px 8px hsla(0, 0%, 0%, 0.1)',
                      ...theme.applyStyles('dark', {
                        background: 'linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)',
                        borderColor: 'primary.dark',
                        boxShadow: '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                      }),
                    },
                  }),
                  selectedItemIndex4 === index &&
                    ((theme) => ({
                      backgroundColor: 'action.selected',
                      borderColor: 'primary.light',
                      ...theme.applyStyles('dark', {
                        borderColor: 'primary.dark',
                      }),
                    })),
                ]}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={[
                      (theme) => ({
                        color: 'grey.400',
                        ...theme.applyStyles('dark', {
                          color: 'grey.600',
                        }),
                      }),
                      selectedItemIndex4 === index && {
                        color: 'primary.main',
                      },
                    ]}
                  >
                    {icon}
                  </Box>
                  <div>
                    <Typography gutterBottom sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                      {title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1.5 }}>
                      {description}
                    </Typography>
                    <Link
                      href={url}
                      target="_blank"
                      color="primary"
                      variant="body2"
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      sx={{
                        fontWeight: 'bold',
                        display: 'inline-flex',
                        alignItems: 'center',
                        '& > svg': { transition: '0.2s' },
                        '&:hover > svg': { transform: 'translateX(2px)' },
                      }}
                    >
                      <span>Registrarme en modalidad</span>
                      <ChevronRightRoundedIcon fontSize="small" sx={{ mt: '1px', ml: '2px' }} />
                    </Link>
                  </div>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={5} sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}>
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
            }}
          >
            <Box
              sx={(theme) => ({
                m: 'auto',
                width: '100%',
                height: '100%',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                overflow: 'hidden',
                backgroundImage: 'var(--items-imageLight)',
                ...theme.applyStyles('dark', {
                  backgroundImage: 'var(--items-imageDark)',
                }),
              })}
              style={{
                '--items-imageLight': items4[selectedItemIndex4].imageLight,
                '--items-imageDark': items4[selectedItemIndex4].imageDark,
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>

    <Container id="features" sx={{ py: { xs: 4, sm: 8 }, mb: '-140px' }}>
      <div>
        <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
          <Box sx={{ display: 'inline-block' }}>
            <Typography variant="h2" sx={{ color: 'text.primary', marginRight: 1 }}>
              ¿Por qué participar como equipo en la
            </Typography>
            <Typography variant="h2" sx={{ color: 'primary.light' }}>
              competencia de ENMICE 2025?
            </Typography>
          </Box>
        </Box>
      </div>
      <Grid container spacing={6}>
        <Grid item xs={12} md={8}>
          <Card variant="outlined" sx={{ display: { xs: 'auto', sm: 'none' }, mt: 4 }}>
            <Box
              sx={(theme) => ({
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: 280,
                backgroundImage: 'var(--items5-imageLight)',
                ...theme.applyStyles('dark', {
                  backgroundImage: 'var(--items5-imageDark)',
                }),
              })}
              style={{
                '--items5-imageLight': items5[selectedItemIndex].imageLight,
                '--items5-imageDark': items5[selectedItemIndex].imageDark,
              }}
            />
            <Box sx={{ px: 2, pb: 2 }}>
              <Typography variant="h6" sx={{ color: 'text.primary', mb: 1.5, whiteSpace: 'pre-line'  }}
                    dangerouslySetInnerHTML={{ __html: selectedFeature2.description.replace(/\n/g, '<br>') }}>
              </Typography>
            </Box>
          </Card>
          <Stack
            direction="column"
            spacing={2}
            useFlexGap
            sx={{
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
            }}
          >
            {items5.map(({ icon, description }, index) => (
              <Card
                key={index}
                component={Button}
                sx={[
                  (theme) => ({
                    p: 3,
                    height: 'fit-content',
                    width: '100%',
                    background: 'none',
                    '&:hover': {
                      background: 'linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)',
                      borderColor: 'primary.light',
                      boxShadow: '0px 2px 8px hsla(0, 0%, 0%, 0.1)',
                      ...theme.applyStyles('dark', {
                        background: 'linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)',
                        borderColor: 'primary.dark',
                        boxShadow: '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                      }),
                    },
                  }),
                  selectedItemIndex === index &&
                    ((theme) => ({
                      backgroundColor: 'action.selected',
                      borderColor: 'primary.light',
                      ...theme.applyStyles('dark', {
                        borderColor: 'primary.dark',
                      }),
                    })),
                ]}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={[
                      (theme) => ({
                        color: 'grey.400',
                        ...theme.applyStyles('dark', {
                          color: 'grey.600',
                        }),
                      }),
                      selectedItemIndex === index && {
                        color: 'primary.main',
                      },
                    ]}
                  >
                    {icon}
                  </Box>
                  <div>
                    <Typography variant="h6" sx={{ color: 'text.primary', mb: 1.5, whiteSpace: 'pre-line'  }}
                    dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, '<br>') }}>
                    </Typography>
                  </div>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}>
          <Grid
          sx={{
            display: { xs: 'none', sm: 'flex' },
            width: '100%',
            alignItems: 'center', // Center vertically
            justifyContent: 'center', // Center horizontally if needed
          }}
        >
            <Box
              sx={(theme) => ({
                width: '100%',
                minHeight: '700px',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                overflow: 'hidden',
                backgroundImage: 'var(--items-imageLight)',
                ...theme.applyStyles('dark', {
                  backgroundImage: 'var(--items-imageDark)',
                }),
              })}
              style={{
                '--items-imageLight': items5[selectedItemIndex].imageLight,
                '--items-imageDark': items5[selectedItemIndex].imageDark,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>

      <Box
        id="hero"
        sx={(theme) => ({
          width: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
          ...theme.applyStyles('dark', {
            backgroundImage:
              'radial-gradient(ellipse 80% 40% at 50% 60%, hsl(210, 100%, 16%), transparent)',
          }),
        })}
      >
      </Box>
    </React.Fragment>
  );
}