import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import imageIndex from '../ImageIndex';

const whiteLogos = [
  { src: imageIndex.SCTAEMLogo, link: 'https://www.gob.mx/aem', name: 'special' },
  { src: imageIndex.FEMIALogo, link: 'https://femiamx.com/#!/-inicio-4-2/', name: 'special' },
  { src: imageIndex.SecretariaEconomiaLogo, link: 'https://www.gob.mx/se', name: 'special'},
  { src: imageIndex.ALCELogo, link: 'https://x.com/agenciaalce', name: ''},
  { src: imageIndex.UACHLogo, link: 'https://uach.mx', name: 'special' },
  { src: imageIndex.VivevolandoLogo, link: 'https://vivevolando.mx', name: 'special' },
  { src: imageIndex.AhChihuahuaLogo, link: 'https://www.visitachihuahua.mx', name: '' },
  { src: imageIndex.Bid360Logo, link: 'https://bid360.mx', name: '' },
  { src: imageIndex.BuroDeConvencionesChihuahuaLogo, link: 'https://www.facebook.com/chihuahuabc/?locale=es_LA', name: '' },
  { src: imageIndex.CamaraDeDiputadosLogo, link: 'https://web.diputados.gob.mx', name: '' },
  { src: imageIndex.CenaltecLogo, link: 'https://www.inadet.com.mx', name: '' },
  { src: imageIndex.ChihuahuaCapitalLogo, link: 'https://www.visitachihuahua.mx', name: '' },
  { src: imageIndex.ChihuahuaAerospaceClusterLogo, link: 'https://aeroclusterchihuahua.com', name: '' },
  { src: imageIndex.CIMAVLogo, link: 'https://cimav.edu.mx', name: '' },
  { src: imageIndex.ConsejoAeroespacialDeJaliscoLogo, link: 'https://www.facebook.com/CAJaliscoMX', name: '' },
  { src: imageIndex.CUCEALogo, link: 'https://cucea.udg.mx', name: '' },
  { src: imageIndex.CutonalaLogo, link: 'http://www.cutonala.udg.mx', name: '' },
  { src: imageIndex.ENAHLogo, link: 'https://www.enah.edu.mx', name: '' },
  { src: imageIndex.FacultadDeIngenieriaLogoBlanco, link: 'https://uach.mx/fing/', name: '' },
  { src: imageIndex.FAULogo, link: 'https://www.fau.org.mx', name: '' },
  { src: imageIndex.GOBSayulaLogo, link: 'https://www.sayula.gob.mx', name: '' },
  { src: imageIndex.GobiernoMunicipalChihuahuaLogo, link: 'https://www.chihuahua.gob.mx', name: '' },
  { src: imageIndex.IndexLogo, link: 'https://indexchihuahua.org.mx', name: '' },
  { src: imageIndex.Explora, link: 'https://sites.google.com/view/exploraspacemx', name: '' },
  { src: imageIndex.CUCEILogo, link: 'https://www.cucei.udg.mx', name: '' },
  { src: imageIndex.LSATLogo, link: 'https://www.instagram.com/leap_sky_aviation_training/', name: '' },
  { src: imageIndex.HackerLogo, link: 'https://zacatecas.hackergarage.mx', name: '' },
  { src: imageIndex.FAMEXLogo, link: 'https://www.f-airmexico.com.mx', name: '' },
  { src: imageIndex.MiNDIndustrialesLogo, link: 'https://www.mindmexico.com', name: '' },
  { src: imageIndex.NASAGoddardLogo, link: 'https://www.nasa.gov/goddard/', name: '' },
  { src: imageIndex.RaymixFundacionLogo, link: 'https://www.instagram.com/raymixmusic', name: '' },
  { src: imageIndex.SemanaMundialDelEspacioLogo, link: 'http://www.sme-mexico.space/2024/', name: '' },
  { src: imageIndex.SociedadAstronomicaChihuahuaLogo, link: 'https://www.facebook.com/SAstroChi/', name: '' },
  { src: imageIndex.SpaceCenterHoustonLogo, link: 'https://spacecenter.org', name: '' },
  { src: imageIndex.SpaceGenerationAdvisoryCouncilLogo, link: 'https://spacegeneration.org', name: '' },
  { src: imageIndex.ThrustersUnlimitedBlancoLogo, link: 'https://thrustersunlimited.space', name: '' },
  { src: imageIndex.TLOscurosLogo, link: 'https://www.talent-land.mx', name: '' },
  { src: imageIndex.UDEGLogo, link: 'https://www.udg.mx', name: '' },
  { src: imageIndex.WorldSpaceWeekLogo, link: 'https://www.worldspaceweek.org', name: '' },
  { src: imageIndex.SIEMENSLogo, link: 'https://www.siemens.com/mx/es.html', name: '' },
];

const logoStyle = {
  width: '150px',
  height: 'auto',
  margin: '0 64px',
  paddingBottom: '40px',
  opacity: 0.7,
  transition: 'transform 0.3s, opacity 0.3s',
};

const hoverStyle = {
  transform: 'scale(1.8)',
  opacity: 1,
};

const extraStyle = {
  width: '300px',
};

export default function SponsorLogos() {
  const logos = whiteLogos;
  const repeatTimes = 1;

  return (
    <Box id="logoCollection" sx={{ py: 4 }}>
      {[...Array(repeatTimes)].map((_, repeatIndex) => (
        <Grid container key={repeatIndex} sx={{ justifyContent: 'center', alignItems: 'center', mt: 0.5, opacity: 0.6 }}>
          {logos.map((logo, index) => {
            const isSpecialLogo = logo.name.includes('special'); // Replace 'special-logo-name' with the actual name

            return (
              <Grid item key={index}>
                <a href={logo.link} target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block' }}>
                  <img
                    src={logo.src}
                    alt={`Logo ${index + 1}`}
                    style={{ ...logoStyle, ...(isSpecialLogo ? extraStyle : {}) }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.transform = hoverStyle.transform;
                      e.currentTarget.style.opacity = hoverStyle.opacity;
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.transform = '';
                      e.currentTarget.style.opacity = logoStyle.opacity;
                    }}
                  />
                </a>
              </Grid>
            );
          })}
        </Grid>
      ))}
    </Box>
  );
}
