import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
//import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ2025() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <div>
        <Box sx={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
          <Typography variant="h1" sx={{ color: 'text.primary', marginRight: 1 }}>
            Preguntas&nbsp;
          </Typography>
          <Typography variant="h1" sx={{ color: 'primary.light' }}>
            frecuentes
          </Typography>
        </Box>
      </div>
      <Box sx={{ width: '100%' }}>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h3" variant="subtitle2">
              ¿Qué es ENMICE?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              El Encuentro Mexicano de Ingeniería en Cohetería Experimental (ENMICE) es el evento y competencia más importante de cohetería experimental y de alta potencia en México. Anualmente reúne a estudiantes, investigadores, profesionistas y entusiastas de la cohetería experimental y el sector aeroespacial en general. Su objetivo es impulsar el desarrollo tecnológico, la colaboración académica y la innovación en estas áreas mediante competencias, talleres y lanzamientos de cohetes con el apoyo de la Agencia Federal de Aviación Civil, Agencia Espacial Mexicana, colaboradores y patrocinadores. Actualmente ENMICE se ha consolidado como una plataforma para impulsar el talento mexicano en el ámbito espacial.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="h3" variant="subtitle2">
              ¿Quiénes pueden participar en el ENMICE 2025?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              El ENMICE está dirigido a estudiantes, investigadores, profesionistas y entusiastas del sector aeroespacial de cohetería experimental y cargas útiles. Pueden participar equipos interesados en competir o presentar proyectos en las áreas de cohetería, cargas útiles y diseño aeroespacial. O bien, puedes participar como público general asistiendo a los días de lanzamiento y exhibición en nuestras distintas sedes.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" variant="subtitle2">
              ¿Cómo puedo registrarme para asistir al evento?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              Para asistir como público general la apertura de accesos se llevará a cabo a partir del mes de enero 2025. Para registrarse como equipo en la competencia, el registro se realizará en línea a través de la página oficial de ENMICE. Los detalles sobre la apertura de inscripciones, costos y modalidades de participación estarán disponibles en la convocatoria disponible a partir del viernes 20 de diciembre del 2024.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography component="h3" variant="subtitle2">
              ¿Qué actividades se realizarán durante el ENMICE 2025?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              Habrá exhibiciones de proyectos aeroespaciales y la presencia de decenas de equipos provenientes de todo el país los cuales podrás conocer asistiendo a nuestra sede de exhibición También contaremos con recorridos por instituciones académicas y workshops de cohetería experimental, así como los esperados lanzamientos de cohetes de alta potencia, experimentales, hidrocohetes y cohetes modelo en la Laguna de Sayula. Además, contaremos con la presencia de diferentes invitados especiales para mesas de diálogo con expertos en el sector aeroespacial.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5d-content"
            id="panel5d-header"
          >
            <Typography component="h3" variant="subtitle2">
              ¿Es necesario formar parte de un equipo para participar en la competencia?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              Sí. La competencia está diseñada para equipos de al menos tres personas. Te invitamos a hacer comunidad en redes sociales para integrarte a un equipo ya existente o mejor aún; crear tu propio equipo. ¡Recuerda que no es necesario tener un perfil aeroespacial o aeronáutico! En nuestra competencia participan perfiles de todas las áreas y siempre serás bienvenida o bienvenido.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel6'}
          onChange={handleChange('panel6')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6d-content"
            id="panel6d-header"
          >
            <Typography component="h3" variant="subtitle2">
              ¿Dónde se llevarán a cabo las actividades del evento?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              La exhibición de proyectos aeroespaciales se realizará en Zapopan, mientras que los lanzamientos de cohetes y pruebas de campo se desarrollarán en la Laguna de Sayula.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
