import * as React from 'react';
import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import IconButton from '@mui/material/IconButton';
//import InputLabel from '@mui/material/InputLabel';
//import TextField from '@mui/material/TextField';

//import { visuallyHidden } from '@mui/utils';
import { useLocation } from 'react-router-dom';

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

export default function ContactUs() {
  const location = useLocation();
  const isSpecificPage = location.pathname === '/academia';
  const isSpecificPage2 = location.pathname === '/campus-chief';
  return (
    <Box
      id="contactUs"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage: isSpecificPage
          ? 'radial-gradient(ellipse 80% 50% at 50% 50%, hsl(210, 100%, 90%), transparent)' // Specific page color
          : 'radial-gradient(ellipse 80% 50% at 50% 50%, hsl(30, 100%, 80%), transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% 50%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
            }}
          >
            Contacta&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: 'inherit',
                color: isSpecificPage ? 'primary.main' : 'rgb(239, 80, 33)', // Specific page color or default color
                ...theme.applyStyles('dark', {
                  color: 'primary.light',
                }),
              })}
            >
              a nuestro equipo
            </Typography>
          </Typography>
          {/*<Stack direction="row" spacing={1} useFlexGap>
              <InputLabel htmlFor="email-contact" sx={visuallyHidden}>
                Correo electrónico
              </InputLabel>
              <TextField
                id="email-contact"
                hiddenLabel
                size="small"
                variant="outlined"
                fullWidth
                aria-label="Escribe tu correo electrónico"
                placeholder="Tu correo electrónico"
                slotProps={{
                  htmlInput: {
                    autoComplete: 'off',
                    'aria-label': 'Escribe tu correo',
                  },
                }}
              />
          </Stack>
          <Stack direction="row" spacing={1} useFlexGap>
              <InputLabel htmlFor="message-contact" sx={visuallyHidden}>
                Mensaje
              </InputLabel>
              <TextField
                id="message-contact"
                hiddenLabel
                size="small"
                variant="outlined"
                fullWidth
                aria-label="Escribe tu mensaje"
                placeholder="Tu mensaje"
                slotProps={{
                  htmlInput: {
                    autoComplete: 'off',
                    'aria-label': 'Escribe tu mensaje',
                  },
                }}
              />
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
            <Button variant="contained" color="primary">
              Enviar
            </Button>
          </Stack>*/}
          <Typography component="h2" variant="h5" sx={{ color: 'text.secondary', mt: 2  }}>
            ¡Escríbenos un mensaje!
          </Typography>
          <Typography component="h2" variant="h4" sx={{ color: 'text.primary', mt: 1 }}>
            <Link
              href="mailto:contacto@enmice.mx"
              target="_blank"
              variant="h5"
              sx={{
                fontWeight: 'bold',
                display: 'inline-flex',
                alignItems: 'center',
                '& > svg': { transition: '0.2s' },
                '&:hover > svg': { transform: 'translateX(2px)' },
              }}
            >
              <span>contacto@enmice.mx</span>
              <ChevronRightRoundedIcon fontSize="small" sx={{ mt: '1px', ml: '2px' }} />
            </Link>
          </Typography>
          <Typography component="h2" variant="h5" sx={{ color: 'text.secondary', mt: 2  }}>
            ¡O búscanos en nuestras redes sociales!
          </Typography>
          <Stack
          direction="row"
          spacing={6}
          useFlexGap
          sx={{ justifyContent: 'left', color: 'text.secondary', mt: 1 }}
        >
          <IconButton
            sx={{
              alignSelf: 'center',
              color: isSpecificPage2 ? 'rgb(239, 80, 33)' : 'inherit',
            }}
            href="https://www.facebook.com/enmicemx"
            aria-label="Facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon sx={{ fontSize: 60 }}/>
          </IconButton>
          <IconButton
            sx={{
              alignSelf: 'center',
              color: isSpecificPage2 ? 'rgb(239, 80, 33)' : 'inherit',
            }}
            href="https://www.instagram.com/enmicemx"
            aria-label="Instagram"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon sx={{ fontSize: 60 }}/>
          </IconButton>
          <IconButton
            sx={{
              alignSelf: 'center',
              color: isSpecificPage2 ? 'rgb(239, 80, 33)' : 'inherit',
            }}
            href="https://mx.linkedin.com/company/enmicemx"
            aria-label="LinkedIn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon sx={{ fontSize: 60 }}/>
          </IconButton>
          <IconButton
            sx={{
              alignSelf: 'center',
              color: isSpecificPage2 ? 'rgb(239, 80, 33)' : 'inherit',
            }}
            href="https://www.youtube.com/@ENMICE"
            aria-label="YouTube"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YouTubeIcon sx={{ fontSize: 60 }}/>
          </IconButton>
        </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
